import React from 'react';
import {
    Create,
    ReferenceInput,
    SelectInput,
    required,
    TextInput,
    SimpleForm,
} from 'react-admin';
import { choicesLineupTypes } from '../../types';

export const LineupCreate: React.FC = props => (
    <Create redirect="list" {...props}>
        <SimpleForm>
            <TextInput label="resources.lineups.fields.name" source="name" />
            <ReferenceInput
                label="resources.lineups.fields.squad"
                source="team_id"
                reference="teams"
                filter={{ currentSeason: true }}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <SelectInput
                    validate={[required()]} optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.lineups.fields.scout_id"
                source="scout_id"
                reference="teams"
                validate={[required()]}
                filter={{ currentPlanning: true }}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput
                label="resources.lineups.fields.lineuptype"
                source="lineuptype"
                choices={choicesLineupTypes}
            />
        </SimpleForm>
    </Create>
);
