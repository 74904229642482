import React, { useCallback, useRef } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  DateInput,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  RaRecord,
  useGetOne,
} from 'react-admin';
import { convertBirthdateToAge, choicesCallType } from '../../types';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { Box } from '@mui/material';

export const CallCreate = props => {
  const location = useLocation();
  const player_id = get(location.state, 'record.player_id');
  const initialValues = {
    date: new Date(),
    player_id,
    type: 'feedback',
  };
  const { isLoading, data: player } = useGetOne('players', { id: player_id });
  const playerRef = useRef();
  if (!isLoading && player && playerRef) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Create transform={transform}
      actions={<PlayerBreadcrumb player_id={player_id} />}
      redirect={`/players/${player_id}/2`} {...props}>
      <SimpleForm defaultValues={initialValues} >
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput
            source="player_id"
            reference="players"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              optionValue="id"
              validate={[required()]}
              filterToQuery={(searchText: any) => ({
                "name@ilike": `%${searchText}%`,
              })}
              noOptionsText="-"
              shouldRenderSuggestions={(val: any) => {
                return val.trim().length > 2;
              }}
              optionText={choice => {
                const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                return `${get(choice, 'name')} - ${birthdate}`;
              }}
              label="resources.calls.fields.player"
            />
          </ReferenceInput>
          <DateInput label="resources.calls.fields.date" source="date" validate={required()} />
          <SelectInput label="resources.calls.fields.type" source="type" fullWidth choices={choicesCallType} />
        </Box>
        <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput label="resources.calls.fields.comments" source="comments" multiline fullWidth rows={10} />
        </Box>
      </SimpleForm>
    </Create>
  );
};
