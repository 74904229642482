import {
    AutocompleteInput,
    ReferenceInput,
    Create,
    SimpleForm,
    required,
    TextInput,
    NumberInput,
    BooleanInput,
    SelectInput,
    useGetIdentity,
} from 'react-admin';
import { get } from 'lodash';
import { convertBirthdateToAge, choiceTeams } from '../../types';
import { Box } from '@mui/material';

export const TransferCreate = props => {
    const {
        isLoading,
        data: { idTokenClaims: { roles } },
    } = useGetIdentity();
    const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
    if (isLoading) return null;
    return (
        <Create {...props}>
            <SimpleForm defaultValues={{ entry: false }}>
                <Box display={{ sm: 'block', md: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput
                            source="player_id"
                            reference="players"
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <AutocompleteInput
                                validate={[required()]}
                                label="resources.transfers.fields.player_id"
                                optionValue="id"
                                noOptionsText="-"
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                optionText={choice => {
                                    const birthdate = convertBirthdateToAge(
                                        choice,
                                        'birthdate',
                                        'agegroup'
                                    );
                                    return `${get(
                                        choice,
                                        'name'
                                    )} - ${birthdate}`;
                                }}
                                filterToQuery={(searchText: any) => ({
                                    'name@ilike': `%${searchText}%`,
                                })}
                            />
                        </ReferenceInput>
                        <ReferenceInput
                            label="resources.transfers.fields.season_id"
                            source="season_id"
                            reference="seasons"
                            sort={{ field: 'name', order: 'DESC' }}
                            style={{ width: 220 }}
                        >
                            <SelectInput
                                optionText="name"
                                translateChoice={false}
                            />
                        </ReferenceInput>
                        <SelectInput
                            label="resources.transfers.fields.team_id"
                            source="team"
                            choices={choiceTeams}
                            style={{ width: 220 }}
                        />
                        <ReferenceInput
                            source="club_id"
                            reference="clubs"
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={5}
                        >
                            <AutocompleteInput
                                label="resources.transfers.fields.club_id"
                                optionText="name"
                                noOptionsText="-"
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                filterToQuery={(searchText: any) => ({
                                    'name@ilike': `%${searchText}%`,
                                })}
                            />
                        </ReferenceInput>
                        <BooleanInput
                            label="resources.transfers.fields.entry"
                            source="entry"
                        />
                    </Box>
                    <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                        <BooleanInput
                            label="resources.transfers.fields.parentsInformed"
                            source="parentsInformed"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.dfbnetApplicationSent"
                            source="dfbnetApplicationSent"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.clubRegistration"
                            source="clubRegistration"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.playerPassportApplication"
                            source="playerPassportApplication"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.playerPassportPhoto"
                            source="playerPassportPhoto"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.confirmationOfCancellation"
                            source="confirmationOfCancellation"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.consentGranted"
                            source="consentGranted"
                        />
                        <BooleanInput
                            label="resources.transfers.fields.transferFinished"
                            source="transferFinished"
                        />
                        <TextInput
                            label="resources.transfers.fields.comments"
                            source="comments"
                            multiline
                            fullWidth
                            rows={4}
                        />
                    </Box>
                    {allowedAccess && (
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.transfers.fields.returnArrangements"
                                source="returnArrangements"
                                multiline
                                fullWidth
                                rows={10}
                            />
                            <NumberInput
                                label="resources.transfers.fields.transferFee"
                                source="transferFee"
                            />
                        </Box>
                    )}
                </Box>
            </SimpleForm>
        </Create>
    );
};
