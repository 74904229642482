import React from 'react';
import {
    SelectInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    FormDataConsumer,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { Box } from '@mui/material';
import LineupDataContainer from './LineupDataContainer';
import { choicesLineupTypes } from '../../types';

export const LineupEdit: React.FC = props => {
    const transform = data => {
        const { squads, lineupMap, ...rest } = data;
        Object.keys(lineupMap).forEach(key => {
            if (lineupMap[key]) {
                lineupMap[key].forEach((e, index) => {
                    // const { id } = e; //, badge, lineupstatus, photo, subtitle, title
                    if (typeof lineupMap[key][index] !== 'undefined') {
                        delete lineupMap[key][index]['badge'];
                        delete lineupMap[key][index]['lineupstatus'];
                        delete lineupMap[key][index]['photo'];
                        delete lineupMap[key][index]['subtitle'];
                        delete lineupMap[key][index]['title'];
                    } else if (lineupMap[key].length === 0) {
                        delete lineupMap[key];
                    }
                });
            }
        });
        return {
            ...rest,
            lineupMap,
        };
    };

    return (
        <Edit
            title="resources.lineups.pages.edit"
            transform={transform}
            {...props}
        >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton alwaysEnable />
                        <DeleteButton />
                    </Toolbar>
                }
            >
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source="name" validate={required()} />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput
                            label="resources.lineups.fields.squad"
                            source="team_id"
                            reference="teams"
                            disabled
                            filterToQuery={(searchText: any) => ({
                                name: searchText,
                            })}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={50}
                        >
                            <SelectInput
                                validate={[required()]} optionText="name" />
                        </ReferenceInput>
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput
                            label="resources.lineups.fields.scout_id"
                            source="scout_id"
                            reference="teams"
                            validate={[required()]}
                            disabled
                            filterToQuery={(searchText: any) => ({
                                name: searchText,
                            })}
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={50}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <SelectInput
                            label="resources.lineups.fields.lineuptype"
                            source="lineuptype"
                            choices={choicesLineupTypes}
                        // disabled={true}
                        />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="lastupdate"
                            disabled={true}
                        />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput
                            source="updatedby"
                            disabled={true}
                        />
                    </Box>
                </Box>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <LineupDataContainer
                            lineuptype={formData.lineuptype}
                            {...rest}
                        />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};
