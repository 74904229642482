import { regex } from "react-admin";
import get from "lodash/get";
import { styled } from "@mui/material";
import { CallIcon } from './pages/calls';
import NoteIcon from '@mui/icons-material/Comment';
import { PlayerRatingField } from "./components/fields/PlayerRatingField";
import { RatingField } from "./components/fields/RatingField";
import { TreatmentIcon } from "./pages/treatments";
export interface OptionValue {
  id: string;
  name: string;
}
export const absenceType = ["injured", "ill", "away"];
export const callType = ["info", "sigskill", "prolongation", "feedback", "engagement", "exchange"];
export const coachType = [
  "coach",
  "scout",
  "video",
  "keeper",
  "athletic",
  "physio",
  "staff",
  "official",
  "coordinator",
  "doc",
];
export const scoutingType = [
  "training",
  "regularmatch",
  "testmatch",
  "fieldtournament",
  "halltournament",
  "repplayer",
  "dfbteam",
  "talentday2020",
  "talentday2021",
  "talentday2022",
  "talentday2023",
  "talentday2024",
];
export const matchLevels = ["KL", "BzL", "LL", "WL", "OL", "RL", "BL"];
export const foots = ["right", "left", "both"];
export const scoutingReviews = ["1", "2", "3", "4"];
export const scoutingRatings = ["a", "b", "c", "d"];
export const memberRatings = ["6", "5", "4", "3", "2", "1"];
export const ratings = [
  "60",
  "50",
  "40",
  "30",
  "20",
  "10",
  "51",
  "41",
  "31",
  "21",
];
export const teams = ['U11', 'U12', 'U13', 'U14', 'U15', 'U16', 'U17', 'U19', 'U23', 'VS'];

export const getEventShort = (type, short, venue) => {
  if (type === "keeper") {
    return `TWT ${short}`;
  } else if (type === "athletic") {
    return `AT ${short}`;
  } else {
    return `${short} - ${venue}`;
  }
};

export const getEventColorForSquad = (team_id, type) => {
  if (type === "keeper") {
    return "#000000";
  }
  const colorMap = {
    kvQ9yq0YONkTkdOcMcCf: "#f38484",
    Z9Sqdw4I5ujj2PjWjsGR: "#b30002",
    jDQz6ERMhDh7CEYwZwPf: "#f9bc7b",
    CgVzSR3DpJl8Z4TLKCvD: "#654300",
    e8ThOTsb5vzCRJzEyBZE: "#00c767",
    CFwItTx3wMOiROGjhbx0: "#008a47",
    fGucMxe0shyV0V5sw26i: "#4a0065",
  };
  colorMap["8nH6xDE89OdEzNplCixE"] = "#004e65";
  return colorMap[team_id];
};
export const validateTime = regex(
  /^([0-1]?\d|2[0-4]):([0-5]\d)(:[0-5]\d)?$/,
  "Keine gültige Uhrzeit!"
);

export const getGameTime = (teamType, series) => {
  if (teamType === "B-Junioren" && series !== "B-Junioren-Bundesliga West")
    return 80;
  else if (teamType === "C-Junioren") return 70;
  else if (teamType === "D-Junioren") return 60;
  else return 90;
};

export const getMatchDuration = (ageclass) => {
  if (!ageclass) {
    console.error(`unkown ageclass ${ageclass}`);
    ageclass = "Herren";
  }
  const matchDurations = {
    Herren: 15,
    "A-Junioren": 15,
    "B-Junioren": 10,
    "C-Junioren": 10,
    "D-Junioren": 10,
  };
  return matchDurations[ageclass];
};

export const calculateTrainingPlayerStatusValues = (
  values,
  changed,
  record,
  notify
) => {
  console.log("calculateTrainingPlayerStatusValues - input", {
    values,
    changed,
    record,
  });
  if (!values || !record || !values.id) return {};
  if (changed === "present") {
    values[changed] = 1 - record[changed];
  }
  if (values[changed] > 0 && changed === "present") {
    values.ill = "";
    values.injured = "";
    values.away = "";
    values.light = "";
  } else if (values[changed] && changed === "ill") {
    values.present = 0;
    values.injured = "";
    values.away = "";
    values.light = "";
  } else if (values[changed] && changed === "injured") {
    values.present = 0;
    values.ill = "";
    values.away = "";
    values.light = "";
  } else if (values[changed] && changed === "away") {
    values.present = 0;
    values.ill = "";
    values.injured = "";
    values.light = "";
  } else if (values[changed] && changed === "light") {
    values.present = 0;
    values.ill = "";
    values.injured = "";
    values.away = "";
  }
  console.log("calculateTrainingPlayerStatusValues - output", {
    values,
  });
  return values;
};

export const calculateMatchPlayerStatusValues = (
  values,
  changed,
  record,
  match,
  notify
) => {
  const gameTime = get(match, "gameTime");
  if (
    values.substituted_out > 0 &&
    values.substituted_in > 0 &&
    values.substituted_out < values.substituted_in
  ) {
    notify(
      `Auswechselung (${values.substituted_out}. min) muss nach Einwechselung (${values.substituted_in}. min) erfolgen!`,
      "error",
      {},
      false
    );
    return {};
  } else if (values.substituted_out > 0 && values.substituted_out > gameTime) {
    notify(
      `Auswechselung (${values.substituted_out}. min) darf nicht größer sein als die Spielzeit (${gameTime} min)!`,
      "error",
      {},
      false
    );
    return {};
  } else if (values.substituted_in > 0 && values.substituted_in > gameTime) {
    notify(
      `Einwechselung (${values.substituted_in}. min) darf nicht größer sein als die Spielzeit (${gameTime} min)!`,
      "error",
      {},
      false
    );
    return {};
  } else if (
    values.substituted_in < 0 ||
    values.substituted_out < 0 ||
    values.assists < 0 ||
    values.goals < 0 ||
    values.yellow < 0
  ) {
    notify(`Wert darf nicht negativ sein!`, "error", {}, false);
    return {};
  }

  if (changed === "starting" || changed === "present") {
    values[changed] = 1 - record[changed];
  }
  if (changed === "present" && values.present > 0) {
    values.injured = "";
    values.away = "";
    values.ill = "";
  }
  if (changed === "ill" && values.ill !== '') {
    values.time_played = 0;
    values.injured = "";
    values.away = "";
    values.substituted_in = 0;
    values.substituted_out = 0;
    values.starting = 0;
    values.goals = 0;
    values.assists = 0;
    values.yellow = 0;
    values.present = 0;
  }
  if (changed === "injured" && values.injured !== '') {
    values.time_played = 0;
    values.ill = "";
    values.away = "";
    values.substituted_in = 0;
    values.substituted_out = 0;
    values.starting = 0;
    values.goals = 0;
    values.assists = 0;
    values.yellow = 0;
    values.present = 0;
  }
  if (changed === "away" && values.away !== '') {
    values.time_played = 0;
    values.ill = "";
    values.injured = "";
    values.substituted_in = 0;
    values.substituted_out = 0;
    values.starting = 0;
    values.goals = 0;
    values.assists = 0;
    values.yellow = 0;
    values.present = 0;
  }
  if (changed === "starting" && values.starting > 0) {
    values.substituted_in = 0;
    values.starting = 1;
    values.ill = "";
    values.injured = "";
    values.away = "";
    values.present = 1;
  }
  if (
    changed === "substituted_in" &&
    record.starting > 0 &&
    values.substituted_in > 0
  ) {
    values.starting = 0;
  }
  if (values.starting > 0 && values.substituted_out === 0) {
    values.time_played = gameTime;
  } else if (values.starting > 0 && values.substituted_out > 0) {
    values.time_played = values.substituted_out;
  } else if (
    values.starting === 0 &&
    values.substituted_in > 0 &&
    values.substituted_out === 0
  ) {
    values.time_played = gameTime - values.substituted_in;
  } else if (
    values.starting === 0 &&
    values.substituted_in > 0 &&
    values.substituted_out > 0
  ) {
    values.time_played = Math.abs(
      values.substituted_out - values.substituted_in
    );
  } else {
    values.time_played = 0;
  }
  return values;
};

export const getAbsencesByTeam = async (dataProvider, team_id, date) => {
  const { data: absences_teams } = (await dataProvider.getList(
    "absences_teams",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: {
        team_id: team_id,
      },
    }
  )) || { data: [], total: 0 };
  // const now = new Date().toISOString();
  const absences = absences_teams.filter(
    (absence) =>
      absence.startDate <= date &&
      absence.endDate >= date &&
      absence.team_id === team_id
  );
  const filtered = {};
  for (const absence of absences) {
    const player_id = absence.player_id;
    filtered[player_id] = {};
    filtered[player_id].key = absence.type;
    filtered[player_id].value = absence.reason;
  }
  console.log("getAbsencesByTeam", { absences_teams, absences, filtered });
  return filtered;
};

export const useMixedRatingStyles = () => ({
  60: { bgcolor: "#006534", color: "white" },
  50: { bgcolor: "#00945e", color: "white" }, // 4fc58c
  40: { bgcolor: "#d2bf41", color: "white" },
  30: { bgcolor: "#f48536", color: "white" },
  20: { bgcolor: "#f44336", color: "white" },
  10: { bgcolor: "#940000", color: "white" },
  51: { bgcolor: "#006534", color: "white" },
  41: { bgcolor: "#00945e", color: "white" },
  31: { bgcolor: "#f48536", color: "white" },
  21: { bgcolor: "#f44336", color: "white" },
});

export const useMatchesPlayersStatusRedStyles = styled("div")(({ theme }) => ({
  0: { color: "grey" },
  1: { color: "red" },
}));
export const useMatchesPlayersStatusGreenStyles = styled("div")(
  ({ theme }) => ({
    0: { color: "grey" },
    1: { color: "#006534" },
  })
);
export const useScoutingReviewStyles = {
  1: { bgcolor: "#006534", color: "white" },
  2: { bgcolor: "#00945e", color: "white" },
  3: { bgcolor: "#f48536", color: "white" },
  4: { bgcolor: "#f44336", color: "white" },
};
export const useRatingStyles = styled("div")(({ theme }) => ({
  6: { bgcolor: "#006534", color: "white" },
  5: { bgcolor: "#00945e", color: "white" }, // 4fc58c
  4: { bgcolor: "#d2bf41", color: "white" },
  3: { bgcolor: "#f48536", color: "white" },
  2: { bgcolor: "#f44336", color: "white" },
  1: { bgcolor: "#940000", color: "white" },
  a: { bgcolor: "#006534", color: "white" },
  b: { bgcolor: "#00945e", color: "white" },
  c: { bgcolor: "#f48536", color: "white" },
  d: { bgcolor: "#f44336", color: "white" },
}));
export const ispro = ["true", "false"];
export const scoutingStatus = ["open", "scouted", "member"];
export const lineupStatus = ["open", "planned", "notplanned"];
export const memberStatus = ["yes", "no"]; //'open',
export const lineupTypes = ["type433", "type442", "type343", "type352"];
export const applicationType = [
  "application",
  "talentday_2021",
  "talentday_2022",
  "talentday_2023",
  "talentday_2024",
];
export const gameType = ["1", "0"];
export const practiceTypes = [
  "team",
  "keeper",
  "athletic",
  "meeting",
  "support",
];
export const calendarTypes = [
  "Freundschaftsspiel",
  "Turnier",
  "Meisterschaft",
  "Pokalspiel",
  ...practiceTypes,
];
export const daysOfWeek = ["0", "1", "2", "3", "4", "5", "6"];
export const textCategories = ["concept", "protocol"];
export const SCP_CLUB_ID = "O2jqSStHa7V7syzgIHNR";
export const textAccess = ["scpyoungstars_base", "scpyoungstars_mgmt"];
export const shareOfSpace = ["half", "full", "quarter", "penaltyarea"];
export const coachLicenses = ["Pro", "A+", "A", "B+", "B", "C"];
export const venues = {
  kr1: {
    name: "Provinzial Youngstars Arena (KR1)",
    street: "Am Berg Fidel 51",
    postal: 48153,
    city: "Münster",
    placeType: "Kunstrasenplatz",
    area: "Kreis Münster",
  },
  kr2: {
    name: "Provinzial Youngstars Arena (KR2)",
    street: "Am Berg Fidel 51",
    postal: 48153,
    city: "Münster",
    placeType: "Kunstrasenplatz",
    area: "Kreis Münster",
  },
  rp: {
    name: "Provinzial Youngstars Arena (RP)",
    street: "Am Berg Fidel 51",
    postal: 48153,
    city: "Münster",
    placeType: "Rasenplatz",
    area: "Kreis Münster",
  },
  profis: {
    name: "Profi-Platz",
    street: "Am Berg Fidel 51",
    postal: 48153,
    city: "Münster",
    placeType: "Rasenplatz",
    area: "Kreis Münster",
  },
  stadium: {
    name: "Stadion an der Hammer Straße",
    street: "Am Berg Fidel 51",
    postal: 48153,
    city: "Münster",
    placeType: "Rasenplatz",
    area: "Kreis Münster",
  },
  hiltrup: {
    name: "Sportanlage TuS Hiltrup Pl. 2",
    street: "Osttor 85",
    postal: 48165,
    city: "Münster",
    placeType: "Kunstrasenplatz",
    area: "Kreis Münster",
  },
};

const createChoicesArray = (
  inputArray: string[],
  translationKey: string
): Array<OptionValue> => {
  const choicesStatus: Array<OptionValue> = [];
  const process = inputArray.map((status) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<void>((resolve) => {
      const newItem: OptionValue = {
        id: status,
        name: translationKey ? `${translationKey}.${status}` : status,
      };
      choicesStatus.push(newItem);
      resolve();
    });
  });
  Promise.all(process);
  return choicesStatus;
}

const createChoicesAgegroup = () => {
  const choices: Array<{}> = [];
  const date = new Date();
  const startYear: number = date.getFullYear() - 8;
  const endYear: number = date.getFullYear() - 20;
  for (let i = startYear; i > endYear; i -= 1) {
    let y = i;
    choices.push({
      id: `${i}`,
      name: `${y}`,
      value: `${y}`,
    });
  }
  return choices;
}
export const choiceTeams = createChoicesArray(teams, 'resources.transfers.teams');
export const choiceCoachLicenses = createChoicesArray(
  coachLicenses,
  "resources.coaches.licenses"
);
export const choiceShareOfSpace = createChoicesArray(
  shareOfSpace,
  "resources.practices.shareOfSpace"
);
export const choiceVenues = createChoicesArray(
  Object.keys(venues),
  "resources.matches.venues"
);
export const choicesTextAccess = createChoicesArray(
  textAccess,
  "resources.texts.access"
);
export const choicesTextCategories = createChoicesArray(
  textCategories,
  "resources.texts.categories"
);
export const choicesAbsenceTypes = createChoicesArray(
  absenceType,
  "resources.absences.types"
);
export const choicesCalendarTypes = createChoicesArray(
  calendarTypes,
  "resources.calendar.types"
);
export const choicesPracticeTypes = createChoicesArray(
  practiceTypes,
  "resources.practices.types"
);
export const choicesCoachType = createChoicesArray(
  coachType,
  "resources.coaches.types"
);
export const choicesAgegroup = createChoicesAgegroup();
export const choicesDaysOfWeek = createChoicesArray(
  daysOfWeek,
  "resources.practices.daysOfWeek"
);
export const choicesGameType = createChoicesArray(
  gameType,
  "resources.gameType"
);
export const choicesIspro = createChoicesArray(ispro, "resources.ispro");
export const choicesIsEntry = createChoicesArray(ispro, 'resources.transfers.entry');
export const choicesMatchLevels = createChoicesArray(
  matchLevels,
  "resources.matchLevels"
);
export const choicesScoutingReviews = createChoicesArray(
  scoutingReviews,
  "resources.scoutingReview"
);
export const choicesScoutingRatings = createChoicesArray(
  scoutingRatings,
  "resources.rating"
);
export const choicesMemberRatings = createChoicesArray(
  memberRatings,
  "resources.rating"
);
export const choicesRatings = createChoicesArray(ratings, "resources.rating");
export const choicesPlayerStatus = createChoicesArray(
  memberStatus,
  "resources.squadplayers.status.player"
);
export const choicesClubStatus = createChoicesArray(
  memberStatus,
  "resources.squadplayers.status.club"
);
export const choicesLineupStatus = createChoicesArray(
  lineupStatus,
  "resources.squadplayers.status.lineup"
);
export const choicesScoutingStatus = createChoicesArray(
  scoutingStatus,
  "resources.squadplayers.status.scouting"
);
export const choicesFoot = createChoicesArray(foots, "resources.players.foot");
export const choicesScoutingType = createChoicesArray(
  scoutingType,
  "resources.scoutings.types"
);
export const choicesLineupTypes = createChoicesArray(
  lineupTypes,
  "resources.lineups.lineuptypes"
);
export const choicesCallType = createChoicesArray(
  callType,
  "resources.calls.types"
);
export const choicesApplicationType = createChoicesArray(
  applicationType,
  "resources.applications.types"
);

export const convertBirthdateToAge = (
  record,
  _birthdate,
  _agegroup
): string => {
  const birthdate = get(record, _birthdate);
  const agegroup = get(record, _agegroup);
  const birthday = new Date(birthdate);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  const output = birthday.toLocaleDateString("de-DE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  if (agegroup && (birthdate === agegroup || output === `01.01.${agegroup}`)) {
    return `XX.XX.${agegroup}`;
  }
  if (birthdate) {
    return `${output} (${age})`;
  }
  return "";
};

const createChoicesContractUntil = (): Array<OptionValue> => {
  const choices: Array<OptionValue> = [];
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear + 5;
  for (let y = currentYear; y <= lastYear; y += 1) {
    choices.push({
      id: `${y}-06-30`,
      name: `30.06.${y}`,
    });
  }
  return choices;
}
export const choicesContractUntil = createChoicesContractUntil();

export const sanitizeRestListProps: (props) => any = ({
  basePath = null,
  currentSort = null,
  data = null,
  defaultTitle = null,
  displayedFilters = null,
  filterDefaultValues = null,
  filterValues = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  hideFilter = null,
  history = null,
  ids = null,
  loading = null,
  loaded = null,
  location = null,
  match = null,
  onSelect = null,
  onToggleItem = null,
  onUnselectItems = null,
  options = null,
  page = null,
  permissions = null,
  perPage = null,
  refetch = null,
  resource = null,
  selectedIds = null,
  setFilters = null,
  setPage = null,
  setPerPage = null,
  setSort = null,
  showFilter = null,
  syncWithLocation = null,
  sort = null,
  total = null,
  ...rest
}) => rest;


export const getBackendUrl = (functionName) =>
  `https://lrhmxlreciaqyszkqtmk.functions.supabase.co/${functionName}`;

export const resolveNoteTypeAttributes = (type: string) => {
  // console.log('resolveNoteTypeAttributes', { type })
  if (type === 'sigskill') {
    return {
      icon: <NoteIcon color="disabled" sx={{ pl: 0.5 }} />,
      resource: 'calls',
      bgColor: '#8b430075',
      labelKey: `resources.calls.types.sigskill`,
      rating: false
    };
  } else if (type === 'info') {
    return {
      icon: <NoteIcon color="disabled" sx={{ pl: 0.5 }} />,
      resource: 'calls',
      bgColor: '#00945e75',
      labelKey: `resources.calls.types.info`,
      rating: false
    };
  }
  else if (type === 'treatment') {
    return {
      icon: <TreatmentIcon color="disabled" sx={{ pl: 0.5 }} />,
      resource: 'treatments',
      bgColor: '#e37de8e8',
      labelKey: `resources.treatments.resource`,
      rating: false
    };
  } else if (callType.includes(type)) {
    return {
      icon: <CallIcon color="disabled" sx={{ pl: 0.5 }} />,
      resource: 'calls',
      bgColor: '#7dbde870',
      labelKey: `resources.calls.types.${type}`,
      rating: false
    };
  } else if (scoutingType.includes(type)) {
    return {
      icon:
        <RatingField label="resources.scoutings.fields.scoutingReview" source="scoutingReview" />,
      resource: 'scoutings',
      bgColor: '#e8cb7d85',
      labelKey: `pos.scouting`,
      rating: true
    };
  } else if (type === 'review') {
    return {
      icon: <PlayerRatingField source="rating" label="resources.reviews.fields.rating" />,
      resource: 'reviews',
      bgColor: '#e88b7d75',
      labelKey: `pos.review`,
      rating: true
    };
  } else {
    return {
      icon: <NoteIcon color="disabled" sx={{ pl: 0.5 }} />,
      resource: 'calls',
      bgColor: '#00945e75',
      labelKey: `resources.calls.types.info`
    };
  }
}

export const fetchFromSupabseFunction = (functionName, body, accesToken) => {
  if (!accesToken)
    throw new Error('Access-Token missing!')
  console.log('fetchFromSupabseFunction', { functionName, body, accesToken })
  return fetch(getBackendUrl(functionName), {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      Authorization: 'Bearer ' + accesToken,
      'Content-Type': 'application/json',
      Origin:
        window.location.hostname === 'localhost'
          ? 'http://localhost:3000'
          : 'https://youngstarsdb.scpreussen-muenster.de',
    },
  });
}