import { useCallback, useRef } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  DateInput,
  SimpleForm,
  NumberInput,
  required,
  useGetOne
} from 'react-admin';
import { InputAdornment, Box } from '@mui/material';
import { convertBirthdateToAge } from '../../types';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';

export const SprintvalueCreate = props => {
  const location = useLocation();
  const player_id = get(location.state, 'record.player_id');
  const initialValues = {
    date: new Date(),
    player_id,
  };
  const { isLoading, data: player } = useGetOne('players', { id: player_id });
  const playerRef = useRef();
  if (!isLoading && player && playerRef) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: any) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Create transform={transform} actions={<PlayerBreadcrumb player_id={player_id} />} basepath={`/players/${player_id}/3`} {...props}>
      <SimpleForm defaultValues={initialValues}>
        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput
            label="resources.sprintvalues.fields.player"
            source="player_id"
            reference="players"
            filterToQuery={(searchText: any) => ({
              name: searchText,
            })}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              validate={[required()]}
              optionValue="id"
              noOptionsText="-"
              shouldRenderSuggestions={(val: any) => {
                return val.trim().length > 2;
              }}
              optionText={choice => {
                const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                return `${get(choice, 'name')} - ${birthdate}`;
              }}
            />
          </ReferenceInput>
        </Box>
        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="date" validate={required()} />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m5"
            label="resources.sprintvalues.fields.m5"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m10"
            label="resources.sprintvalues.fields.m10"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m20"
            label="resources.sprintvalues.fields.m20"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m30"
            label="resources.sprintvalues.fields.m30"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
