import React, { Fragment, useCallback, useRef, useState } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  DateInput,
  SimpleForm,
  required,
  RaRecord,
  SelectInput,
  TextInput,
  Confirm,
  DeleteButton,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useCreate,
  useDataProvider,
  useNotify,
  usePermissions,
  useTranslate,
  DataProvider,
  useUpdateMany,
  useRedirect,
  useGetOne,
} from 'react-admin';
import { choicesAbsenceTypes, convertBirthdateToAge, sanitizeRestListProps } from '../../types';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { useFormContext } from "react-hook-form";

const getUnitsInAbsence = async (
  resource: 'matches' | 'trainings',
  dataProvider: DataProvider,
  startDate: Date,
  endDate: Date,
  player_id
) => {
  const { data, total } = (await dataProvider.getList(`${resource}_players_with_date`, {
    pagination: { perPage: 100, page: 1 },
    sort: { field: 'date', order: 'ASC' },
    filter: {
      player_id,
      "date@gte": startDate.toISOString(),
      "date@lte": endDate.toISOString(),
    },
  })) || { data: [], total: 0 };
  return { ids: data.flatMap(record => record.id), data, count: total };
};

const AbsenceCreateToolbar: React.FC<ToolbarProps> = () => {
  const [updateMany] = useUpdateMany();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { getValues } = useFormContext();
  // const { isLoading } = usePermissions();
  // const [confirmOpen, setConfirmOpen] = useState(false);
  // const [linkedTrainings, setLinkedTrainings] = useState({ ids: [], data: [], count: 0 });
  // const [linkedMatches, setLinkedMatches] = useState({ ids: [], data: [], count: 0 });
  // const [absence, setAbsence] = useState<RaRecord>();
  // const [create] = useCreate();
  // const redirect = useRedirect();
  // const translate = useTranslate();
  // const handleDialogClose = e => {
  //   setConfirmOpen(false);
  //   e.stopPropagation();
  // };

  // const handleConfirm = useCallback(
  //   event => {
  //     event.stopPropagation();

  //     setConfirmOpen(false);
  //     notify('ra.notification.updated', {
  //       type: 'info',
  //       messageArgs: { smart_count: linkedTrainings.count + linkedMatches.count },
  //     });
  //     redirect(`/players/${absence.player_id}/7`);
  //   },
  //   [linkedTrainings, linkedMatches, updateMany, notify, redirect, absence]
  // );

  const handleSave = useCallback(
    async (event) => {
      // event.stopPropagation();
      const values = getValues();
      const absence = {
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
        type: values.type,
        reason: values.reason,
        player_id: values.player_id,
        id: values.id,
      }
      // create('absences', {
      //   data: absence,
      // });
      const trainingsInAbsence = await getUnitsInAbsence(
        'trainings',
        dataProvider,
        absence.startDate,
        absence.endDate,
        absence.player_id
      );
      const matchesInAbsence = await getUnitsInAbsence(
        'matches',
        dataProvider,
        absence.startDate,
        absence.endDate,
        absence.player_id
      );
      if (trainingsInAbsence.count > 0 || matchesInAbsence.count > 0) {
        // setConfirmOpen(true);
        // setLinkedTrainings(trainingsInAbsence);
        // setLinkedMatches(matchesInAbsence);
        // setAbsence(absence);
        console.log('confirmOpen', {
          trainingsInAbsence,
          matchesInAbsence,
          // confirmOpen
        });

        updateMany(
          'trainings_players',
          {
            ids: trainingsInAbsence.ids,
            data:
            {
              [absence.type]: absence.reason,
              present: 0,
            },
          },
          {
            mutationMode: "pessimistic"
          }
        );
        updateMany(
          'matches_players',
          {
            ids: matchesInAbsence.ids,
            data: {
              [absence.type]: absence.reason,
              present: 0,
            },
          },
          {
            mutationMode: "pessimistic"
          }
        );
        notify('ra.notification.created', {
          type: 'info',
          messageArgs: { smart_count: (trainingsInAbsence.count + matchesInAbsence.count) },
        });
      } else {
        notify('ra.notification.created', {
          type: 'info',
          messageArgs: { smart_count: 1 },
        });
      }
    },
    [dataProvider, getValues, notify]
  );

  // if (!dataProvider) return null;
  return (
    <Toolbar style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
      <Fragment>
        <SaveButton type="button" onClick={handleSave} />
        {/* <Confirm
          isOpen={confirmOpen}
          loading={isLoading}
          title="resources.absences.notification.training_update_title"
          content={translate('resources.absences.notification.training_update_content', {
            trainings: linkedTrainings.count,
            matches: linkedMatches.count,
          })}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        /> */}
        <DeleteButton />
      </Fragment>
    </Toolbar>
  );
};

export const AbsenceCreate = props => {
  const location = useLocation();
  const player_id = get(location.state, 'record.player_id');
  const initialValues = {
    startDate: new Date(),
    player_id,
    type: 'injured',
  };
  const { isLoading, data: player } = useGetOne('players', { id: player_id });
  const playerRef = useRef();
  if (!isLoading && player && playerRef) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  return (
    <Create transform={transform} actions={<PlayerBreadcrumb player_id={player_id} />} redirect={`/players/${player_id}/1`}>
      <SimpleForm
        toolbar={<AbsenceCreateToolbar />}
        defaultValues={initialValues}
      >
        <ReferenceInput
          source="player_id"
          reference="players"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput
            label="resources.absences.fields.player"
            validate={[required()]}
            optionValue="id"
            optionText={choice => {
              const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
              return `${get(choice, 'name')} - ${birthdate}`;
            }}
            filterToQuery={(searchText: any) => ({
              "name@ilike": `%${searchText}%`,
            })}
          />
        </ReferenceInput>
        <DateInput label="resources.absences.fields.startDate" source="startDate" validate={required()} />
        <DateInput label="resources.absences.fields.endDate" source="endDate" validate={required()} />
        <SelectInput
          label="resources.absences.fields.type"
          source="type"
          choices={choicesAbsenceTypes}
          validate={[required()]}
        />
        <TextInput source="reason" label="resources.absences.fields.reason" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
