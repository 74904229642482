import {
  ReferenceInput,
  Edit,
  SimpleForm,
  required,
  useGetIdentity,
  SelectInput,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  DateField,
  Labeled,
} from 'react-admin';
import { Autocomplete, Box } from '@mui/material';

const transformData = (data) => {
  const push_enabled = data.push_enabled ? 1 : 0;
  const admin_push_enabled = data.admin_push_enabled ? 1 : 0;
  delete data.push_enabled;
  delete data.admin_push_enabled;
  console.log('transformData', data);
  return {
    ...data,
    push_enabled,
    admin_push_enabled,
  };
};

export const ProfileEdit = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if (isLoading || !allowedAccess) return null;
  return (
    <Edit
      transform={transformData}
      title="resources.profiles.actions.edit" {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="full_name" />
        <ReferenceInput
          label="resources.profiles.fields.player_id"
          source="player_id"
          reference="players"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            validate={[required()]}
            noOptionsText="-"
            shouldRenderSuggestions={(val: any) => {
              return val.trim().length > 2;
            }}
            filterToQuery={(searchText: any) => ({
              'name@ilike': `%${searchText}%`,
            })}
            optionText="name"
          />
        </ReferenceInput>
        <TextInput source="fcm_token" />
        <BooleanInput source="push_enabled" />
        <BooleanInput source="admin_push_enabled" />
        <Labeled label="resources.profiles.fields.lastupdate">
          <DateField source="lastupdate"
            locales="de-DE"
            options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Berlin' }} />
        </Labeled>
        <Labeled label="resources.profiles.fields.updated_at">
          <DateField source="updated_at"
            locales="de-DE"
            options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Berlin' }} />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
