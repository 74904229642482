import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
    DragDropContext,
    DropResult,
    DraggableLocation,
} from 'react-beautiful-dnd';
import {
    Box,
    Card,
    CardHeader,
    Avatar,
    IconButton,
    CardContent,
    CardActions,
    Grid,
    useMediaQuery,
} from '@mui/material';
import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import get from 'lodash/get';
import LineupItemList from './LineupItemList';
import { reorderLineupMap, grid } from './reorder';
import type { LineupMap } from './reorder';
import { useTranslate } from 'react-admin';
import { convertBirthdateToAge } from '../../types';

export interface LineupProps {
    lineupMap: any;
}

export interface ContainerProps {
    lineuptype: string;
}

// const positions = {
//  'type433' : ['tw','lv','liv','riv','rv','dm','om1','om2','lf','rf','st','sub'],
//  'type343' : ['tw','liv','ziv','riv','lm','dm1','dm2','rm','lf','rf','st','sub'],
// };
const positions = [
    'p1',
    'p3',
    'p5',
    'p4',
    'p2',
    'p6',
    'p8',
    'p10',
    'p11',
    'p7',
    'p9',
    'sub',
];

const Root = styled.div`
    background-color: '#222222';
    box-sizing: border-box;
    padding: ${grid * 2}px;

    /* flexbox */
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const Column = styled.div`
    margin: 0 ${grid * 2}px;
`;

const VerticalScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #00945e40;
  padding: ${grid}px;
  max-height: 90vh
  overflow: auto;
`;
//rgba(0, 0, 0, 0.05);

const HorizontalScrollContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: #00945e40;
    padding: ${grid}px;
    max-width: 90vw;
    overflow: auto;
`;

const HorizontalLineup433: React.FC<LineupProps> = props => {
    const { lineupMap } = props;
    return (
        <HorizontalScrollContainer>
            <Grid container>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p11}
                        title="LF"
                        listId="p11"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '5%',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p9}
                        title="ST"
                        listId="p9"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p7}
                        title="RF"
                        listId="p7"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '5%',
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p10}
                        title="OM"
                        listId="p10"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p6}
                        title="DM"
                        listId="p6"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '5%',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p8}
                        title="OM"
                        listId="p8"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p3}
                        title="LAV"
                        listId="p3"
                        listType="card"
                        style={{ marginLeft: 5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p5}
                        title="LIV"
                        listId="p5"
                        listType="card"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p4}
                        title="RIV"
                        listId="p4"
                        listType="card"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p2}
                        title="RAV"
                        listId="p2"
                        listType="card"
                    />
                </Grid>

                <Grid item xs={12}>
                    <LineupItemList
                        quotes={lineupMap.p1}
                        title="TW"
                        listId="p1"
                        listType="card"
                        style={{ marginLeft: '38%' }}
                    />
                </Grid>
            </Grid>
        </HorizontalScrollContainer>
    );
};

const HorizontalLineup442: React.FC<LineupProps> = props => {
    const { lineupMap } = props;
    return (
        <HorizontalScrollContainer>
            <Grid container>
                <Grid item xs={6}>
                    <LineupItemList
                        quotes={lineupMap.p10}
                        title="ST"
                        listId="p10"
                        listType="card"
                        style={{ marginLeft: '50%' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LineupItemList
                        quotes={lineupMap.p9}
                        title="ST"
                        listId="p9"
                        listType="card"
                        style={{ marginRight: '50%' }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p11}
                        title="LF"
                        listId="p11"
                        listType="card"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p8}
                        title="OM"
                        listId="p8"
                        listType="card"
                        style={{ marginLeft: 5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p6}
                        title="DM"
                        listId="p6"
                        listType="card"
                        style={{ marginLeft: 5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p7}
                        title="RF"
                        listId="p7"
                        listType="card"
                        style={{ marginLeft: 5, marginRight: 5 }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p3}
                        title="LAV"
                        listId="p3"
                        listType="card"
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p5}
                        title="LIV"
                        listId="p5"
                        listType="card"
                        style={{ marginLeft: 5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p4}
                        title="RIV"
                        listId="p4"
                        listType="card"
                        style={{ marginLeft: 5 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p2}
                        title="RAV"
                        listId="p2"
                        listType="card"
                        style={{ marginLeft: 5, marginRight: 5 }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <LineupItemList
                        quotes={lineupMap.p1}
                        title="TW"
                        listId="p1"
                        listType="card"
                        style={{ marginLeft: '38%' }}
                    />
                </Grid>
            </Grid>
        </HorizontalScrollContainer>
    );
};

const HorizontalLineup343: React.FC<LineupProps> = props => {
    const { lineupMap } = props;
    return (
        <HorizontalScrollContainer>
            <Grid container>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p11}
                        title="LF"
                        listId="p11"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '10%',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p9}
                        title="ST"
                        listId="p9"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p7}
                        title="RF"
                        listId="p7"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '10%',
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p3}
                        title="LM"
                        listId="p3"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p10}
                        title="DM"
                        listId="p10"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p8}
                        title="DM"
                        listId="p8"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p2}
                        title="RM"
                        listId="p2"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p5}
                        title="LIV"
                        listId="p5"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p6}
                        title="ZIV"
                        listId="p6"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p4}
                        title="RIV"
                        listId="p4"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <LineupItemList
                        quotes={lineupMap.p1}
                        title="TW"
                        listId="p1"
                        listType="card"
                        style={{ marginLeft: '38%' }}
                    />
                </Grid>
            </Grid>
        </HorizontalScrollContainer>
    );
};

const HorizontalLineup352: React.FC<LineupProps> = props => {
    const { lineupMap } = props;
    return (
        <HorizontalScrollContainer>
            <Grid container>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p11}
                        title="ST"
                        listId="p11"
                        listType="card"
                        style={{ marginLeft: '30%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p10}
                        title="OM"
                        listId="p10"
                        listType="card"
                        style={{
                            marginLeft: '15%',
                            marginRight: '15%',
                            marginTop: '20%',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p9}
                        title="ST"
                        listId="p9"
                        listType="card"
                        style={{ marginRight: '30%' }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p3}
                        title="LAV"
                        listId="p3"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p7}
                        title="DM"
                        listId="p7"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p8}
                        title="DM"
                        listId="p8"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LineupItemList
                        quotes={lineupMap.p2}
                        title="RAV"
                        listId="p2"
                        listType="card"
                        style={{ marginLeft: 0 }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p5}
                        title="LIV"
                        listId="p5"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p6}
                        title="ZIV"
                        listId="p6"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LineupItemList
                        quotes={lineupMap.p4}
                        title="RIV"
                        listId="p4"
                        listType="card"
                        style={{ marginLeft: '15%', marginRight: '15%' }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <LineupItemList
                        quotes={lineupMap.p1}
                        title="TW"
                        listId="p1"
                        listType="card"
                        style={{ marginLeft: '38%' }}
                    />
                </Grid>
            </Grid>
        </HorizontalScrollContainer>
    );
};

const LineupDataContainer: React.FC<ContainerProps> = props => {
    const { lineuptype } = props;
    const { getValues, setValue } = useFormContext();
    const values = getValues();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [, setError] = useState();
    const translate = useTranslate();
    const [lineupMap, setLineupMap] = useState<LineupMap>();

    const onDragEnd = (result: DropResult) => {
        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source: DraggableLocation = result.source;
        const destination: DraggableLocation = result.destination;
        const newLineupMap = reorderLineupMap({
            lineupMap,
            source,
            destination,
        });
        if (newLineupMap) {
            setLineupMap(newLineupMap);
            console.log('onDragEnd - newLineupMap', {
                values,
            });
            setValue('lineupMap', newLineupMap);
        }
    };

    useEffect(() => {
        const { team_id, scout_id, lineupMap } = values;
        const getData = async (team_id, scout_id) => {
            const members = await dataProvider.getList('squadplayers', {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'rating', order: 'DESC' },
                filter: { team_id, scoutingstatus: 'member', invisible: false },
            });
            const scouted = await dataProvider.getList('squadplayers', {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'rating', order: 'DESC' },
                filter: {
                    scoutingstatus: 'scouted',
                    lineupstatus: 'planned',
                    team_id: scout_id,
                    invisible: false
                },
            });

            const { data: scoutedData } = scouted;
            const { data: memberData } = members;
            console.log('LineupDataContainer - getData', {
                scoutedData,
                memberData,
            });
            return [...memberData, ...scoutedData];
        };
        if (team_id && scout_id) {
            getData(team_id, scout_id)
                .then(data => {
                    const newLineupMap: LineupMap = {};
                    positions.forEach(position => {
                        newLineupMap[position] = [];
                    });
                    const squadplayersAssigned = [];
                    data.forEach(squadplayer => {
                        const key = `p${squadplayer.id}`;
                        const photo = get(squadplayer, 'photo.src') || '';
                        const rating = get(squadplayer, 'rating') || '';
                        const birthdate = convertBirthdateToAge(
                            squadplayer,
                            'birthdate',
                            'agegroup'
                        );
                        positions.forEach(position => {
                            if (
                                lineupMap &&
                                lineupMap[position] &&
                                lineupMap[position].length > 0 &&
                                lineupMap[position].filter(e => e.id === key)
                                    .length > 0 &&
                                !squadplayersAssigned.includes(key)
                            ) {
                                newLineupMap[position].push({
                                    id: key,
                                    subtitle: birthdate,
                                    title: squadplayer.name,
                                    badge: rating,
                                    photo: photo,
                                    clubstatus: squadplayer.clubstatus,
                                    playerstatus: squadplayer.playerstatus,
                                    lineupstatus: squadplayer.lineupstatus,
                                });
                                squadplayersAssigned.push(key);
                            } else if (
                                !squadplayersAssigned.includes(key) &&
                                position === 'sub'
                            ) {
                                newLineupMap['sub'].push({
                                    id: key,
                                    subtitle: birthdate,
                                    title: squadplayer.name,
                                    badge: rating,
                                    photo: photo,
                                    clubstatus: squadplayer.clubstatus,
                                    playerstatus: squadplayer.playerstatus,
                                    lineupstatus: squadplayer.lineupstatus,
                                });
                                squadplayersAssigned.push(key);
                            }
                        });
                    });
                    setLineupMap(newLineupMap);
                    setValue('lineupMap', newLineupMap);
                    console.log('LineupDataContainer - then', {
                        data,
                        newLineupMap,
                        values,
                    });
                    setLoading(false);
                    // setLineupType(lineuptype)
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [lineuptype]);

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return loading ? null : (
        <DragDropContext onDragEnd={onDragEnd}>
            <Root>
                <Box width={1} display={{ md: 'flex', lg: 'flex' }}>
                    <Box flex={3} mr={{ md: '1em', lg: '1em' }}>
                        <Card sx={{ padding: 0 }}>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#006534',
                                        }}
                                        aria-label={translate(
                                            'resources.lineups.boxes.startup'
                                        )}
                                    >
                                        11
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={translate(
                                    'resources.lineups.boxes.startup'
                                )}
                                subheader={translate(
                                    `resources.lineups.lineuptypes.${lineuptype}`
                                )}
                            />
                            <CardContent
                                sx={{
                                    padding: 0,
                                }}
                            >
                                {/* <VerticalLineup433 lineupMap={lineupMap} /> */}
                                {lineuptype === 'type433' ? (
                                    <HorizontalLineup433
                                        lineupMap={lineupMap}
                                    />
                                ) : null}
                                {lineuptype === 'type442' ? (
                                    <HorizontalLineup442
                                        lineupMap={lineupMap}
                                    />
                                ) : null}
                                {lineuptype === 'type343' ? (
                                    <HorizontalLineup343
                                        lineupMap={lineupMap}
                                    />
                                ) : null}
                                {lineuptype === 'type352' ? (
                                    <HorizontalLineup352
                                        lineupMap={lineupMap}
                                    />
                                ) : null}
                            </CardContent>
                            <CardActions disableSpacing />
                        </Card>
                    </Box>
                    <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                        <Card
                            sx={{
                                padding: 0,
                            }}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar
                                        aria-label={translate(
                                            'resources.lineups.boxes.substitution'
                                        )}
                                        sx={{
                                            backgroundColor: '#006534',
                                        }}
                                    >
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={translate(
                                    'resources.lineups.boxes.substitution'
                                )}
                                subheader=""
                            />
                            <CardContent
                                sx={{
                                    padding: 0,
                                }}
                            >
                                {isSmall ? (
                                    <HorizontalScrollContainer>
                                        <Column>
                                            <LineupItemList
                                                quotes={lineupMap.sub}
                                                title=""
                                                listId="sub"
                                                listType="card"
                                            />
                                        </Column>
                                    </HorizontalScrollContainer>
                                ) : (
                                    <VerticalScrollContainer>
                                        <Column>
                                            <LineupItemList
                                                quotes={lineupMap.sub}
                                                title=""
                                                listId="sub"
                                                listType="card"
                                            />
                                        </Column>
                                    </VerticalScrollContainer>
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Root>
        </DragDropContext>
    );
};
export default LineupDataContainer;
