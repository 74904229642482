import {
  Edit,
  AutocompleteInput,
  ReferenceInput,
  DateInput,
  TextInput,
  required,
  SelectInput,
  SimpleForm,
  useRecordContext,
  useGetOne,
  useGetRecordId,
} from 'react-admin';
import { convertBirthdateToAge, choicesCallType } from '../../types';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { get } from 'lodash';
import { PlayerTabInfoToolbar } from '../players/PlayerTabInfoToolbar';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const TreatmentEdit = () => {
  const location = useLocation();
  const [, , id] = location.pathname.split("/");
  const { data, isLoading } = useGetOne("treatments", { id });
  if (isLoading) return null;
  return (
    <Edit title="resources.treatments.actions.edit"
      actions={<PlayerBreadcrumb player_id={data.player_id} />}
      redirect={`/players/${data.player_id}/2`}
    >
      <SimpleForm
        toolbar={<PlayerTabInfoToolbar record={data} />}
      >
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput
            source="player_id"
            reference="players"
            options={{ disabled: true }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              disabled={true}
              validate={[required()]}
              optionValue="id"
              filterToQuery={(searchText: any) => ({
                "name@ilike": `%${searchText}%`,
              })}
              noOptionsText="-"
              shouldRenderSuggestions={(val: any) => {
                return val.trim().length > 2;
              }}
              optionText={choice => {
                const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                return `${get(choice, 'name')} - ${birthdate}`;
              }}
              label="resources.treatments.fields.player"
            />
          </ReferenceInput>
          <DateInput label="resources.treatments.fields.date" source="date" validate={required()} />
          {/* <SelectInput label="resources.calls.fields.type" source="type" fullWidth choices={choicesCallType} /> */}
        </Box>
        <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput label="resources.treatments.fields.comments" source="comments" multiline fullWidth rows={10} />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
