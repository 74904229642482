import React, { useCallback } from "react";
import { Edit, TabbedForm, RaRecord, TopToolbar, useDataProvider, useNotify } from "react-admin";
import { sanitizeRestListProps } from "../../types";
import { DetailsFormTab } from "./tabs/DetailsFormTab";
import { MatchesPlayersFormTab } from "./tabs/MatchesPlayersFormTab";
import { EditFormTab } from "./tabs/EditFormTab";
import moment from "moment";
import { ImportConfig, ImportButton } from "react-admin-import-csv";
import { useLocation } from "react-router-dom";
import { MatchesPlayersStatsFormTab } from "./tabs/MatchesPlayersStatsFormTab";

const MatchTitle: React.FC<{
  record: {
    id: string;
    homeTeam: string;
    guestTeam: string;
  };
}> = (props) => {
  return (
    <span>
      {props.record
        ? `"${props.record.id}: ${props.record.homeTeam} - ${props.record.guestTeam}"`
        : ""}
    </span>
  );
};


const EditActions = props => {
  const dataProvider = useDataProvider();
  const location = useLocation();
  const notify = useNotify();
  const [, , matchId] = location.pathname.split('/');

  const lookupMatch = async matchId => {
    const { data: matchData } = await dataProvider.getOne('matches', { id: matchId });
    if (!matchData) return false;
    return matchData;
  };

  const lookupMatchPlayer = async (matchId, playerName) => {
    const { data: matchPlayerData } = await dataProvider.getList('matches_players', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'name', order: 'ASC' },
      filter: { match_id: matchId, name: playerName }
    });
    // console.log('lookupMatchPlayer', { matchId, playerName, matchPlayerData })
    if (matchPlayerData.length === 0) return false;
    return matchPlayerData[0];
  }

  const updateMatchPlayer = async (matchPlayerId, data, previousData) => {
    return dataProvider.update('matches_players', {
      id: matchPlayerId,
      data,
      previousData
    });
  }

  const processRowDfbNetMatchReport = async row => {
    if (!row.Spielberichtsnummer) return null;
    let type = row['Heimmannschaft'] === 'SC Preußen Münster' ? 'H' : 'G';
    const match = await lookupMatch(matchId)
    const gameTime = match['gameTime'];
    let players = {}
    let substitutionsIn = [], substitutionsOut = [], yellowCards = [];
    for (let i = 1; i <= 11; i++) {
      let playerFullName = row[`${type}-S${i}-Spieler`];
      let playerName = playerFullName.split(',');
      playerName = `${playerName[1]} ${playerName[0]}`.trim()
      players[`${playerName}`] = {
        starting: 1,
        ill: "",
      }
      let playerNameSubstIn = row[`${type}-S${i}-Ausw-Spieler`];
      let playerNameSubstOut = row[`${type}-S${i}-Ausw-für-Spieler`];
      let timeSubstitution = row[`${type}-S${i}-Ausw-Zeit`];
      if (playerNameSubstIn && playerNameSubstOut && timeSubstitution) {
        playerNameSubstIn = playerNameSubstIn.split(',');
        playerNameSubstIn = `${playerNameSubstIn[1]} ${playerNameSubstIn[0]}`.trim();
        playerNameSubstOut = playerNameSubstOut.split(',');
        playerNameSubstOut = `${playerNameSubstOut[1]} ${playerNameSubstOut[0]}`.trim();
        substitutionsIn[playerNameSubstIn] = {
          time: timeSubstitution,
          played: gameTime - timeSubstitution
        }
        substitutionsOut[playerNameSubstOut] = {
          time: timeSubstitution,
          played: timeSubstitution
        }
      }
      let playerNameYellow = row[`${type}-S${i}-Gelb-Spieler`];
      let timeYellow = row[`${type}-S${i}-Gelb-Zeit`];
      if (playerNameYellow && timeYellow) {
        playerNameYellow = playerNameYellow.split(',');
        playerNameYellow = `${playerNameYellow[1]} ${playerNameYellow[0]}`.trim();
        yellowCards[playerNameYellow] = timeYellow
      }
    }
    for (let i = 1; i <= 11; i++) {
      let playerFullName = row[`${type}-A${i}-Spieler`];
      if (playerFullName) {
        let playerName = playerFullName.split(',');
        playerName = `${playerName[1]} ${playerName[0]}`.trim()
        players[`${playerName}`] = {
          starting: 0,
          ill: "",
        }
      }
    }
    for (let name in players) {
      if (substitutionsIn[name] && substitutionsOut[name]) {
        players[name]['time_played'] = substitutionsOut[name]['time'] - substitutionsIn[name]['time']
      } else if (substitutionsIn[name]) {
        players[name]['substituted_in'] = substitutionsIn[name]['time'];
        players[name]['time_played'] = substitutionsIn[name]['played'];
      } else if (substitutionsOut[name]) {
        players[name]['substituted_out'] = substitutionsOut[name]['time'];
        players[name]['time_played'] = substitutionsOut[name]['played'];
      } else {
        players[name]['time_played'] = (players[name]['starting'] === 1) ? gameTime : 0;
      }
      if (yellowCards[name]) {
        players[name]['yellow'] = 1;
      }
    }
    console.log("processRowDfbNetMatchReport", { row, players })
    await Promise.all(Object.keys(players).map(async player => {
      let currentPlayer = players[player];
      let matchPlayer = await lookupMatchPlayer(matchId, player);
      if (!matchPlayer) {
        notify('resources.matches.notification.match_player_not_found', {
          type: 'warning',
          messageArgs: {
            name: player
          },
          undoable: false
        });
        console.log("failed lookupMatchPlayer", { player })
        return Promise.reject(`${player}`);
      } else {
        let matchPlayerId = matchPlayer['id'];
        console.log("updating", { data: currentPlayer, previousData: matchPlayer, matchPlayerId })
        return updateMatchPlayer(matchPlayerId, currentPlayer, matchPlayer);
      }
    }))
    return {};
  };

  const processRowTracktics = async row => {
    // console.log("processRowTracktics", { row });
    if (!row['Team or Player'] || row['First Name'] === '-') return null;
    const match = await lookupMatch(matchId)
    let players = {}
    let playerFullName = `${row['First Name']} ${row['Last Name']}`;
    let player = {
      time_on_pitch: row['Time on Pitch (mins)'],
      distance_km: row['Distance (km)'],
      distance_hi_km: row['Distance in HI (km)'],
      sprints: row['Sprints'],
      sprints_progressive: row['Progressive Sprints'],
      top_speed: row['Top Speed (km/h)'],
      avg_top_speed: row['Average Sprint Top Speed'],
    }
    players[playerFullName] = player;

    // for (let name in players) {
    //   players[name]['yellow'] = 1;
    // }

    console.log("processRowTracktics", { row, players })
    await Promise.all(Object.keys(players).map(async player => {
      let currentPlayer = players[player];
      let matchPlayer = await lookupMatchPlayer(matchId, player);
      if (!matchPlayer) {
        notify('resources.matches.notification.match_player_not_found', {
          type: 'warning',
          messageArgs: {
            name: player
          },
          undoable: false
        });
        return Promise.reject(`${player}`);
      } else {
        let matchPlayerId = matchPlayer['id'];
        console.log("updating", { data: currentPlayer, previousData: matchPlayer, matchPlayerId })
        return updateMatchPlayer(matchPlayerId, currentPlayer, matchPlayer);
        // return Promise.resolve(true);
      }
    }))
    return {};
  };

  const dfbnetImportConfig: ImportConfig = {
    logging: true,
    parseConfig: {
      // For all options see: https://www.papaparse.com/docs#config
      delimiter: ';', // auto-detect
      dynamicTyping: true,
    },
    transformRows: async (csvRows: any[]) => {
      const rows = await Promise.all(csvRows.map(processRowDfbNetMatchReport));
      console.log('dfbnetImportConfig.transformRows', rows);
      return rows.filter(n => n);
    },
    disableCreateMany: true,
  };
  const trackticsImportConfig: ImportConfig = {
    logging: true,
    parseConfig: {
      // For all options see: https://www.papaparse.com/docs#config
      delimiter: ',', // auto-detect
      dynamicTyping: true,
    },
    transformRows: async (csvRows: any[]) => {
      const rows = await Promise.all(csvRows.map(processRowTracktics));
      console.log('trackticsImportConfig.transformRows', rows);
      return rows.filter(n => n);
    },
    disableCreateMany: true,
  };
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <ImportButton label="DFBnet Spielbericht importieren" {...props} {...dfbnetImportConfig} />
      <ImportButton label="Tracktics Daten importieren" {...props} {...trackticsImportConfig} />
    </TopToolbar>
  );
};

export const MatchEdit = (props) => {
  const transform = useCallback((data: RaRecord) => {
    data.isHome = data.isHome ? 1 : 0;
    delete data.club_id;
    delete data.venueChoice;
    return {
      ...data,
      date: moment(
        `${data.date} ${data.matchTime}`,
        "YYYY-MM-DD HH:mm"
      ).toDate(),
      matchDate: moment(data.date).format("DD.MM.YYYY"),
    };
  }, []);
  return (
    <Edit transform={transform} actions={<EditActions />} {...props} title={<MatchTitle {...props} />}>
      <TabbedForm {...sanitizeRestListProps(props)}>
        <MatchesPlayersFormTab />
        <DetailsFormTab />
        <EditFormTab />
        <MatchesPlayersStatsFormTab />
      </TabbedForm>
    </Edit>
  );
};
