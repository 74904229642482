import React, { Fragment, useCallback, useMemo, useState } from "react";
import get from "lodash/get";
import { calculateTrainingPlayerStatusValues } from "../../../types";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import IconCancel from "@mui/icons-material/Cancel";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import HealingIcon from "@mui/icons-material/Healing";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import {
  SaveContextProvider,
  useNotify,
  useTranslate,
  useUpdate,
  useRefresh,
  useRecordContext,
} from "ra-core";
import { Button, SimpleForm } from "ra-ui-materialui";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  styled,
  Toolbar,
} from "@mui/material";
import { ResourceContextProvider, SaveButton, TextInput } from "react-admin";

// badge: { backgroundColor: "white", color: "grey" }
export const TrainingPlayerStatusIcon: React.FC<{
  value: string;
  source: string;
  onClick: any;
}> = ({ value, source, onClick }) => {
  const iconGreen = value ? { color: "#006534" } : { color: "grey" };
  const iconRed = value ? { color: "#f44336" } : { color: "grey" };
  const icons = {
    away: <ThumbDownIcon sx={iconRed} />,
    injured: <HealingIcon sx={iconRed} />,
    light: <ThumbsUpDownIcon sx={iconGreen} />,
    ill: <LocalHospitalIcon sx={iconRed} />,
  };
  return (
    <Button
      onClick={onClick}
      variant="text"
      label={value}
      sx={{ textTransform: "none" }}
    >
      {icons[source]}
    </Button>
  );
};

const QuickCreateToolbar = ({ submitting, onCancel, handleSave, ...props }) => (
  <Toolbar {...props}>
    <SaveButton onClick={handleSave} {...props} />
    <Button label="ra.action.cancel" onClick={onCancel}>
      <IconCancel />
    </Button>
  </Toolbar>
);

const QuickUpdateForm = ({
  handleSubmitWithRedirect,
  onCancel,
  onSave,
  source,
  id,
  ...props
}) => {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving] = useState();
  const record = useRecordContext();
  const handleSave = useCallback(
    (values) => {
      const newValues = calculateTrainingPlayerStatusValues(
        values,
        source,
        record,
        notify
      );
      update(
        "trainings_players",
        { id, data: newValues, previousData: values },
        {
          mutationMode: "pessimistic",
          onSuccess: () => {
            onSave(values);
            refresh();
            notify("resources.trainings.notification.status_update", {
              type: "info",
            });
            refresh();
          },
          onError: () => {
            notify("resources.trainings.notification.status_update_error", {
              type: "warning",
            });
          },
        }
      );
    },
    [id, source, notify, onSave, record, refresh, update]
  );
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        toolbar={
          <QuickCreateToolbar
            handleSave={handleSave}
            onCancel={onCancel}
            submitting={saving}
          />
        }
        sx={{ padding: 0 }}
        {...props}
      >
        <TextInput
          label={`resources.trainings.fields.reason`}
          source={source}
          autoFocus
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export const TrainingPlayerStatusEditButton: React.FC<{
  source: string;
  label?: string;
  disabled?: boolean;
}> = ({ source, disabled }) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const handleNewClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowUpdateDialog(true);
    },
    [setShowUpdateDialog]
  );
  const handleCloseCreate = useCallback(() => {
    setShowUpdateDialog(false);
  }, [setShowUpdateDialog]);
  const handleSave = useCallback(
    (post) => {
      setShowUpdateDialog(false);
    },
    [setShowUpdateDialog]
  );
  return (
    <Fragment>
      <ResourceContextProvider value="trainings_players">
        <>
          <TrainingPlayerStatusIcon
            onClick={disabled ? null : handleNewClick}
            value={get(record, source)}
            source={source}
          />
          {disabled || !record ? null : (
            <Dialog
              fullWidth
              open={showUpdateDialog}
              onClose={handleCloseCreate}
            >
              <DialogTitle>
                {translate(`resources.trainings.fields.${source}`)}{" "}
                {translate("resources.trainings.actions.update_value")}
              </DialogTitle>
              <DialogContent>
                <QuickUpdateForm
                  onCancel={handleCloseCreate}
                  onSave={handleSave}
                  handleSubmitWithRedirect={handleSave}
                  source={source}
                  record={record}
                  // basePath={`/trainings/${record.training_id}/1`}
                  id={record.id}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      </ResourceContextProvider>
    </Fragment>
  );
};
