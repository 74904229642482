import { Edit, AutocompleteInput, ReferenceInput, DateInput, NumberInput, required, SimpleForm, useGetOne, useRecordContext } from 'react-admin';
import { InputAdornment, Box } from '@mui/material';
import { convertBirthdateToAge } from '../../types';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { get } from 'lodash';
import { PlayerTabInfoToolbar } from '../players/PlayerTabInfoToolbar';
import { useLocation } from 'react-router-dom';

export const SprintvalueEdit = props => {
  const location = useLocation();
  const [, , id] = location.pathname.split("/");
  const { data, isLoading } = useGetOne("sprintvalues", { id });
  // const { player_id } = useRecordContext();
  if (isLoading) return null;
  return (
    <Edit
      title={`resources.sprintvalues.actions.edit`}
      actions={<PlayerBreadcrumb player_id={data.player_id} {...props} />}
      redirect={`/players/${data.player_id}/6`}
    >
      <SimpleForm
        toolbar={<PlayerTabInfoToolbar record={data} />}
      >
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput
            source="player_id"
            reference="players"
            options={{ disabled: true }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              label="resources.sprintvalues.fields.player"
              disabled={true}
              validate={[required()]}
              optionValue="id"
              optionText={choice => {
                const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                return `${get(choice, 'name')} - ${birthdate}`;
              }}
              filterToQuery={(searchText: any) => ({
                "name@ilike": `%${searchText}%`,
              })}
            />
          </ReferenceInput>
        </Box>
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <DateInput source="date" validate={required()} />
        </Box>
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m5"
            label="resources.sprintvalues.fields.m5"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m10"
            label="resources.sprintvalues.fields.m10"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m20"
            label="resources.sprintvalues.fields.m20"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
        <Box mr={{ xs: 0, sm: '0.5em' }}>
          <NumberInput
            source="m30"
            label="resources.sprintvalues.fields.m30"
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
