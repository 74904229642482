import React, { useCallback, useState } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { calculateMatchPlayerStatusValues } from "../../../types";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  useRefresh,
  useDataProvider,
  useNotify,
  useUpdate,
  useRecordContext,
} from "ra-core";
import { Button } from "ra-ui-materialui";
import { FieldProps, RaRecord } from "react-admin";

export const MatchPlayerStatusIcon: React.FC<{
  value: string;
  source: string;
}> = ({ value, source }) => {
  const classes = {
    red: {
      0: { color: "grey" },
      1: { color: "red" },
    },
    green: {
      0: { color: "grey" },
      1: { color: "#006534" },
    },
  };

  if (source === "starting") {
    return <AssignmentTurnedInIcon sx={classes.green[value]} />;
  } else if (source === "present") {
    return <ThumbUpIcon sx={classes.green[value]} />;
  } else {
    return null;
  }
};

type MatchPlayerStatusFieldProps = {
  match?: RaRecord;
};

export const MatchPlayerStatusField: React.FC<
  FieldProps & MatchPlayerStatusFieldProps
> = ({ source, match }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const [update] = useUpdate();
  const [submitting, setSubmitting] = useState(false);
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(
    async () => {
      setSubmitting(true);
      const newValues = calculateMatchPlayerStatusValues(
        record,
        source,
        record,
        match,
        notify
      );
      update("matches_players", {
        id: record.id,
        data: newValues,
        previousData: record,
      });
      refresh();
      setSubmitting(false);
      notify("resources.matches.notification.match_player_status_update", {
        type: "info",
        undoable: false,
      });
    },
    [dataProvider, record, source] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <MatchPlayerStatusIcon value={get(record, source)} source={source} />
    </Button>
  );
};

MatchPlayerStatusField.propTypes = {
  match: PropTypes.any,
};
