import {
  Edit,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
  SelectInput,
  SimpleForm,
  BooleanInput,
  SaveButton,
  useNotify,
  useRedirect,
  useUpdate,
  Toolbar,
  RaRecord,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { useCallback, useRef } from 'react';
import { convertBirthdateToAge, choicesRatings, sanitizeRestListProps } from '../../types';
import { PlayerBreadcrumb } from '../players/PlayerBreadcrumb';
import { get } from 'lodash';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PlayerTabInfoToolbar } from '../players/PlayerTabInfoToolbar';
import { useFormContext } from "react-hook-form";

const ReviewEditSaveButton = () => {
  const playerRef = useRef();
  const { record: { player_id } } = useRecordContext();
  const { isLoading, data: player } = useGetOne('players', { id: player_id }) || { isLoading: true, data: {} };
  if (!isLoading) {
    playerRef.current = player.name;
  }
  const transform = useCallback((data: RaRecord) => {
    return {
      ...data,
      name: `${playerRef.current}`,
    };
  }, []);
  const [update] = useUpdate();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const handleSave = useCallback(
    (redirect) => {
      const { values } = getValues();
      values.name = player.name;
      update('players', {
        id: values.player_id,
        data: {
          name: values.name,
          rating: values.rating
        },
        previousData: player
      });
      update('reviews', {
        id: values.id,
        data: values,
        previousData: null
      }, {
        onSuccess: ({ data: newRecord }) => {
          notify('ra.notification.updated', { type: 'info', messageArgs: { smart_count: 1 } });
          redirectTo(`/players/${values.player_id}/3`);
        },
      });
    },
    [update, notify, redirectTo, player]
  );
  return (<SaveButton type="button" onClick={handleSave} transform={transform} />);
};

const ReviewEditToolbar = props => {
  return (
    <Toolbar {...sanitizeRestListProps(props)}>
      <ReviewEditSaveButton />
    </Toolbar>
  );
};

export const ReviewEdit = props => {
  const location = useLocation();
  const [, , id] = location.pathname.split("/");
  const { data, isLoading } = useGetOne("reviews", { id });
  if (isLoading) return null;
  return (
    <Edit
      title="resources.reviews.actions.edit"
      actions={<PlayerBreadcrumb player_id={data.player_id} />}
      redirect={`/players/${data.player_id}/3`}
      {...props}
    >
      <SimpleForm
        toolbar={<PlayerTabInfoToolbar record={data} />}
      // toolbar={<ReviewEditToolbar />}
      >
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <ReferenceInput
            source="player_id"
            reference="players"
            options={{ disabled: true }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              validate={[required()]}
              disabled={true}
              optionValue="id"
              label="resources.reviews.fields.player"
              optionText={choice => {
                const birthdate = convertBirthdateToAge(choice, 'birthdate', 'agegroup');
                return `${get(choice, 'name')} - ${birthdate}`;
              }}
              filterToQuery={(searchText: any) => ({
                "name@ilike": `%${searchText}%`,
              })}
            />
          </ReferenceInput>
          <SelectInput
            label="resources.reviews.fields.rating"
            source="rating"
            choices={choicesRatings}
            validate={[required()]}
          />
          <BooleanInput label="resources.reviews.fields.potential" source="potential" />
        </Box>
        <Box flex={5} mr={{ xs: 0, sm: '0.5em' }}>
          <TextInput label="resources.reviews.fields.comments" multiline fullWidth source="comments" rows={10} />
        </Box>
      </SimpleForm>
    </Edit>
  );
};
