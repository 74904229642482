/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Fragment, useMemo, useState, useCallback } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  useTranslate,
  Toolbar,
  CREATE,
  useNotify,
  useDataProvider,
  SaveContextProvider,
  useUnselectAll,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  useRedirect,
  useCreate,
  useRefresh,
} from "react-admin"; // eslint-disable-line import/no-unresolved
import IconContentAdd from "@mui/icons-material/Add";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { get } from "lodash";
import { choicesLineupStatus, choicesScoutingStatus } from "../../../types";

const QuickCreateToolbar = ({ submitting, onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button label="ra.action.cancel" onClick={onCancel}>
      <IconCancel />
    </Button>
  </Toolbar>
);

const QuickCreate = ({ onCancel, onSave, selectedIds, ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [create] = useCreate();
  const unselect = useUnselectAll("applications");
  const dataProvider = useDataProvider();
  const [saving] = useState();
  const defaultValues = {
    playerIds: selectedIds,
    scoutingstatus: "scouted",
    lineupstatus: "open",
  };
  const handleSave = useCallback(
    (values) => {
      const { playerIds, team_id, lineupstatus, scoutingstatus } = values;
      if (team_id && playerIds && playerIds.length > 0) {
        playerIds.forEach(async (playerId) => {
          const { data: squadPlayerExists } = await dataProvider.getList(
            "squadplayers",
            {
              pagination: { page: 1, perPage: 5 },
              sort: { field: "id", order: "ASC" },
              filter: { team_id: team_id, player_id: playerId },
            }
          );
          if (squadPlayerExists && squadPlayerExists.length > 0) {
            notify("resources.squadplayers.notification.exists", {
              type: "warning",
              messageArgs: { player: get(squadPlayerExists[0], "name") || "" },
            });
          } else {
            const data = {
              player_id: playerId,
              team_id: team_id,
              scoutingstatus,
              lineupstatus,
            };
            create("teams_players", {
              data,
              meta: {
                onSuccess: {
                  callback: ({ payload: { data } }) => {
                    onSave(data);
                  },
                },
                onError: {
                  callback: ({ error }) => {
                    notify(error.message, { type: "error" });
                  },
                },
              },
            });
          }
        });
        unselect();
        notify("resources.squadplayers.notification.added", { type: "info" });
        redirect(
          `/squadplayers?order=DESC&sort=rating&filter=${encodeURI(
            JSON.stringify({ team_id, scoutingstatus })
          )}`
        );
        refresh();
      }
    },
    [dataProvider, unselect, notify, redirect, onSave]
  );
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        defaultValues={defaultValues}
        onSubmit={handleSave}
        toolbar={
          <QuickCreateToolbar
            onCancel={onCancel}
            // onSave={onSave}
            submitting={saving}
          />
        }
        {...props}
      >
        <ReferenceInput
          label="resources.squadplayers.fields.squad"
          source="team_id"
          reference="teams"
          filter={{ current: true }}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            validate={[required()]} optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="resources.squadplayers.fields.player"
          source="playerIds"
          reference="players"
        >
          <SelectArrayInput
            optionText="name"
            translateChoice={false}
            contentEditable={false}
          />
        </ReferenceArrayInput>

        <SelectInput
          label="resources.squadplayers.fields.lineupstatus"
          source="lineupstatus"
          choices={choicesLineupStatus}
        />

        <SelectInput
          label="resources.squadplayers.fields.scoutingstatus"
          source="scoutingstatus"
          choices={choicesScoutingStatus}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

interface IAddSquadplayer {
  selectedIds: any;
}

export const AddSquadplayer: React.FC<IAddSquadplayer> = ({ selectedIds }) => {
  const translate = useTranslate();

  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const handleNewClick = useCallback(
    (event) => {
      event.preventDefault();
      setShowCreateDialog(true);
    },
    [setShowCreateDialog]
  );

  const handleCloseCreate = useCallback(() => {
    setShowCreateDialog(false);
  }, [setShowCreateDialog]);

  const handleSave = useCallback(
    (post) => {
      setShowCreateDialog(false);
    },
    [setShowCreateDialog]
  );

  return (
    <Fragment>
      <Button
        data-testid="button-add-squadplayers"
        onClick={handleNewClick}
        label="resources.squadplayers.actions.add"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        data-testid="dialog-add-squadplayers"
        fullWidth
        open={showCreateDialog}
        onClose={handleCloseCreate}
        aria-label={translate("resources.squadplayers.actions.add")}
      >
        <DialogTitle>
          {translate("resources.squadplayers.actions.add")}
        </DialogTitle>
        <DialogContent>
          <QuickCreate
            onCancel={handleCloseCreate}
            onSave={handleSave}
            selectedIds={selectedIds}
            basePath="/squadplayers"
            resource="squadplayers"
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
